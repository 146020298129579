import PropTypes from "prop-types"
import triangleIndicatorSelectedSection from "../../assets/img/flecha3-px.svg"
import triangleIndicatorSelectedMiddleSection from "../../assets/img/flecha5-px.svg"
import classNames from "classnames"
import {
  BIMI_TOPS_COVERS,
  BOAT_SEATS,
  WOVEN_FLOORING
} from "modules/cleaning-guide-marine/modules/General/constants"

const TriangleIndicatorSectionSelected = ({ sectionId }) => {
  return (
    <div>
      <div
        className={classNames({
          "mx-auto px-5 d-flex col-9": true,
          "justify-content-start": sectionId === BOAT_SEATS,
          "justify-content-center": sectionId === BIMI_TOPS_COVERS,
          "justify-content-end": sectionId === WOVEN_FLOORING
        })}
      >
        <div className="triangle bg-white">
          <img
            src={
              sectionId === BIMI_TOPS_COVERS
                ? triangleIndicatorSelectedMiddleSection
                : triangleIndicatorSelectedSection
            }
            width="50px"
            height="30px"
            alt=""
            className="p-0 triangle"
          />
        </div>
      </div>
      <div className="triangle-section-indicator p-0 m-0"></div>
    </div>
  )
}

TriangleIndicatorSectionSelected.propTypes = {
  sectionId: PropTypes.string.isRequired
}

export default TriangleIndicatorSectionSelected
