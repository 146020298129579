/* eslint-disable indent */
import { useContext, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { Col, Row } from "reactstrap"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import useToggle from "hooks/useToggle"
import ErrorMessage from "components/ErrorMessage"
import ModalContext from "modules/kizuna/components/SamplingRequest/context"
import ModalPrivacyPolicy from "modules/masiala/components/SamplingRequest/components/RequestForm/components/ModalPrivacyPolicy"
import { getCurrentInstanceId } from "services/instances"
import { VALIDATION_MESSAGES as validationMessages } from "utils/forms"
import {
  REQUEST_SAMPLES_INFO,
  MODAL_TYPES_DOWNLOAD,
  CONTACT_INFO,
  MODAL_TYPES,
  MODAL_TYPES_THANKS_URL
} from "modules/kizuna/components/RequestsSection/utils"
import "../../styles/samplingrequest.scss"
import ColorsSelect from "modules/kizuna/components/SamplingRequest/components/ColorsSelect"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import classNames from "classnames"
import {
  createAnonimalOrder,
  createDownloadLog
} from "modules/masiala/components/SamplingRequest/components/RequestForm/mutation"
import { handleSamplesOrder, handleURLPdfAccordingToFormType } from "./utils"
import SelectCompanyType from "modules/masiala/components/SamplingRequest/components/RequestForm/components/SelectCompanyType"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import SelectCountry from "modules/masiala/components/SamplingRequest/components/RequestForm/components/SelectCountry"
import SelectRegion from "modules/masiala/components/SamplingRequest/components/RequestForm/components/SelectRegion"
import { REGIONS_BY_COUNTRY } from "modules/masiala/components/SamplingRequest/components/RequestForm/components/SelectRegion/constants"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { MSGS } from "components/select/SelectCity/constants"

const COLOR_SCHEMA = Yup.object()
  .shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
    data: Yup.object().shape({
      catalogue: Yup.string(),
      pattern: Yup.string(),
      color: Yup.string(),
      reference: Yup.string()
    })
  })
  .nullable(false)

const RequestForm = ({ handleCallbackToShowThankYouMessage }) => {
  const intl = useIntl()
  const { state, actions } = useStateMachine({ updateAction })
  const context = useContext(ModalContext)
  const [toggle, toggable] = useToggle()
  const [checked, setChecked] = useState(false)
  const [createOrder, { error: errorCreateOrder }] = useMutation(createAnonimalOrder)
  const [logDownload, { error: errorlogDownload }] = useMutation(createDownloadLog)

  const initialValues = {
    colours: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    region: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    comment: "",
    type: context.type,
    privacyPolicy: false
  }

  const validationSchema = Yup.object({
    colours:
      context === REQUEST_SAMPLES_INFO
        ? Yup.array()
            .of(COLOR_SCHEMA)
            .required(intl.formatMessage(validationMessages.required))
            .min(1)
        : Yup.string(),
    firstName: Yup.string().required(intl.formatMessage(validationMessages.required)),
    lastName: Yup.string().required(intl.formatMessage(validationMessages.required)),
    companyName: Yup.string().required(intl.formatMessage(validationMessages.required)),
    companyType: Yup.object().nullable().required(intl.formatMessage(validationMessages.required)),
    email: Yup.string().email().required(intl.formatMessage(validationMessages.required)),
    phone: Yup.string().required(intl.formatMessage(validationMessages.required)),
    country: Yup.object().nullable().required(intl.formatMessage(validationMessages.required)),
    region:
      context === REQUEST_SAMPLES_INFO &&
      Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string()
        })
        .nullable()
        .required(intl.formatMessage(validationMessages.required)),
    address:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    zipCode:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    city:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    comment:
      context === CONTACT_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    privacyPolicy: Yup.bool().oneOf([true], intl.formatMessage(validationMessages.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const countryValue = useWatch({ control, name: "country" })
  const regionValue = useWatch({ control, name: "region" })

  const handlePostSubmit = () => {
    actions.updateAction({
      ...state,
      sampleCount: []
    })
    setChecked(false)
    reset()
  }

  const onSubmit = async (values) => {
    console.log("onSubmit values: ", values)
    const {
      firstName,
      lastName,
      email,
      privacyPolicy,
      type,
      companyName,
      companyType: { value: companyTypeId },
      country: { value: countryId },
      region: { value: regionId },
      city,
      colours,
      comment,
      zipCode,
      ...rest
    } = values

    try {
      if (MODAL_TYPES_DOWNLOAD.includes(context.type)) {
        const { pdfURL } = handleURLPdfAccordingToFormType({
          formType: MODAL_TYPES[context.type]
        })
        const downloadLogRequest = {
          firstName,
          lastName,
          email,
          companyType: companyTypeId,
          instance: getCurrentInstanceId(),
          // Pattern ID for TWEED (checker and artisan is missing on API)
          patternId: "UGF0dGVybk5vZGU6MjQx",
          resourceType: MODAL_TYPES_THANKS_URL[context.type],
          resourceUrl: pdfURL
        }

        const { data } = await logDownload({ variables: { input: downloadLogRequest } })

        if (data) handleCallbackToShowThankYouMessage()
      }

      if (context.type === REQUEST_SAMPLES_INFO.type) {
        const inputForAnonimalOrder = {
          instance: getCurrentInstanceId(),
          source: "landing kizuna usa",
          name: firstName,
          surname: lastName,
          email,
          company: companyName,
          companyType: companyTypeId,
          country: countryId,
          region: regionId,
          city: city.toLowerCase(),
          acceptTerms: privacyPolicy,
          postalCode: zipCode,
          anonimalSamples: handleSamplesOrder(colours),
          ...rest
        }
        const result = await createOrder({ variables: { input: inputForAnonimalOrder } })
        if (result?.data?.storeAnonimalOrder?.success) handleCallbackToShowThankYouMessage()
      }

      handlePostSubmit()
    } catch (e) {
      console.log("ERROR crete contact on CONTACTFORM: ", e)
      handlePostSubmit()
    }
  }

  return (
    <form id={context.type} onSubmit={handleSubmit(onSubmit)} noValidate className="px-lg-5">
      <Row>
        <Col md={12}>
          <div>
            <p
              className={classNames({
                "text-center px-5 m-0": true,
                "mb-4": !context?.info
              })}
            >
              {context.subtitle}
            </p>
            {context?.info && <p className="text-center px-5 m-0">{context.info}</p>}
          </div>
        </Col>
      </Row>
      {context === REQUEST_SAMPLES_INFO && (
        <>
          <Row>
            <Col md={12}>
              <div className="form-group">
                <p className="p-0 m-0 mt-2">
                  {intl.formatMessage({
                    id: "SamplingRequest.labelSamples",
                    defaultMessage: "Requested samples"
                  })}
                </p>
                <ColorsSelect id="colours" name="colours" control={control} setValue={setValue} />
                <ErrorMessage className="my-1" weight="normal">
                  {errors?.colours?.message}
                </ErrorMessage>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelName",
                defaultMessage: "Name"
              })}
              *
            </p>
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelName",
                defaultMessage: "Name"
              })}
              id="firstName"
              name="firstName"
              type="text"
              className="form-control rounded-0"
              {...register("firstName")}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.firstName?.message}
            </ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelSurname",
                defaultMessage: "Surname"
              })}
              *
            </p>
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelSurname",
                defaultMessage: "Surname"
              })}
              id="lastName"
              name="lastName"
              type="text"
              className="form-control rounded-0"
              {...register("lastName")}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.lastName?.message}
            </ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              *
            </p>
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              id="companyName"
              name="companyName"
              type="text"
              className="form-control rounded-0"
              {...register("companyName")}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.companyName?.message}
            </ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelCompanyType",
                defaultMessage: "Company type"
              })}
              *
            </p>
            <SelectCompanyType
              name="companyType"
              control={control}
              customStyles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: 0,
                  border: "2px solid #bdbdbd",
                  fontSize: "1rem",
                  fontWeight: "lighter",
                  fontFamily: "Gill Sans, sans-serif",
                  marginTop: "0.5rem !important",
                  color: "gray !important"
                })
              }}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.companyType?.message}
            </ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "Form.field.Country",
                defaultMessage: "Country"
              })}
              *
            </p>
            <SelectCountry
              labelClassName={""}
              name="country"
              control={control}
              options={COUNTRIES_FOR_USA}
              isSearchable
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.country?.message}
            </ErrorMessage>
          </div>
        </Col>
        {context === REQUEST_SAMPLES_INFO && (
          <>
            <Col md={6}>
              <div className="form-group">
                <p className="p-0 m-0 mt-2">
                  {intl.formatMessage({
                    id: "Form.field.Region",
                    defaultMessage: "Region"
                  })}
                  *
                </p>
                <SelectRegion
                  labelClassName={""}
                  name="region"
                  control={control}
                  isSearchable={false}
                  defaultOptions={REGIONS_BY_COUNTRY[countryValue?.value]}
                />
                <ErrorMessage className="my-1" weight="normal">
                  {errors?.region?.message}
                </ErrorMessage>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <p className="p-0 m-0 mt-2">
                  {intl.formatMessage({
                    id: "SamplingRequest.labelCity",
                    defaultMessage: "City"
                  })}
                  *
                </p>
                <div className="form-group">
                  <input
                    className="form-control rounded-0 text-secondary"
                    placeholder={
                      !regionValue
                        ? intl.formatMessage(MSGS.withoutRegion)
                        : intl.formatMessage(MSGS.city)
                    }
                    id="city"
                    name="city"
                    type="text"
                    disabled={!regionValue}
                    {...register("city")}
                  />
                  <ErrorMessage className="my-1" weight="normal">
                    {errors?.city?.message}
                  </ErrorMessage>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <p className="p-0 m-0 mt-2">
                  {intl.formatMessage({
                    id: "SamplingRequest.labelPostal",
                    defaultMessage: "Postal Code"
                  })}
                  *
                </p>
                <input
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelPostal",
                    defaultMessage: "Postal Code"
                  })}
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  className="form-control rounded-0"
                  {...register("zipCode")}
                />
                <ErrorMessage className="my-1" weight="normal">
                  {errors?.zipCode?.message}
                </ErrorMessage>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <p className="p-0 m-0 mt-2">
                  {intl.formatMessage({
                    id: "SamplingRequest.labelAddress",
                    defaultMessage: "Address"
                  })}
                  *
                </p>
                <input
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelAddress",
                    defaultMessage: "Address"
                  })}
                  id="address"
                  name="address"
                  type="text"
                  className="form-control rounded-0"
                  {...register("address")}
                />
                <ErrorMessage className="my-1" weight="normal">
                  {errors?.address?.message}
                </ErrorMessage>
              </div>
            </Col>
          </>
        )}
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelEmail",
                defaultMessage: "Email"
              })}
              *
            </p>
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelEmail",
                defaultMessage: "Email"
              })}
              id="email"
              name="email"
              type="text"
              className="form-control rounded-0"
              {...register("email")}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.email?.message}
            </ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <p className="p-0 m-0 mt-2">
              {intl.formatMessage({
                id: "SamplingRequest.labelPhone",
                defaultMessage: "Phone"
              })}
              *
            </p>
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelPhone",
                defaultMessage: "Phone"
              })}
              id="phone"
              name="phone"
              type="text"
              className="form-control rounded-0"
              {...register("phone")}
            />
            <ErrorMessage className="my-1" weight="normal">
              {errors?.phone?.message}
            </ErrorMessage>
          </div>
        </Col>
        {context.type === CONTACT_INFO.type && (
          <Col md={12}>
            <div className="form-group">
              <p className="p-0 m-0 mt-2">
                {intl.formatMessage({
                  id: "SamplingRequest.labelComment",
                  defaultMessage: "Comment"
                })}
                *
              </p>
              <input
                placeholder={intl.formatMessage({
                  id: "SamplingRequest.labelComment",
                  defaultMessage: "Comment"
                })}
                id="comment"
                name="comment"
                type="text"
                className="form-control rounded-0"
                {...register("comment")}
              />
              <ErrorMessage className="my-1" weight="normal">
                {errors?.comment?.message}
              </ErrorMessage>
            </div>
          </Col>
        )}
      </Row>

      <div className="form-group justify-content-between form-check d-lg-flex text-start mt-4 mb-4 footer-form-container">
        <div>
          <input
            type="checkbox"
            name="privacyPolicy"
            id="privacyPolicy"
            className="form-check-input rounded-0"
            checked={checked}
            onClick={toggable}
            {...register("privacyPolicy")}
          />
          <label htmlFor="privacyPolicy" className="form-check-label fw-light text-secondary">
            <FormattedMessage
              id="SamplingRequest.privacyPolicy"
              defaultMessage="I've read and agree the <strong>Privacy Policy</strong>"
              values={{
                strong: (...chunks) => <strong>{chunks}</strong>
              }}
            />
          </label>
          <ErrorMessage className="my-1" weight="normal">
            {errors?.privacyPolicy?.message}
          </ErrorMessage>
          <ModalPrivacyPolicy
            btnClassName="btn btn-sample"
            isOpen={toggle}
            toggle={toggable}
            acceptPrivacyPolicy={() => {
              setChecked(true)
              setValue("privacyPolicy", true)
            }}
          />
        </div>
        <div className="col-lg-6 text-end d-flex align-items-end justify-content-end py-4 py-lg-0">
          <button type="submit" disabled={isSubmitting} className="btn btn-sample">
            {!isSubmitting ? (
              <p className="m-0 p-0 text-white">
                {context === REQUEST_SAMPLES_INFO && (
                  <FormattedMessage
                    id="SamplingRequest.btnLabelRequest"
                    defaultMessage="ORDER NOW"
                  />
                )}
                {MODAL_TYPES_DOWNLOAD.includes(context.type) && (
                  <FormattedMessage
                    id="SamplingRequest.btnLabelRequestDownloadCatalogue"
                    defaultMessage="DOWNLOAD"
                  />
                )}
                {context.type === CONTACT_INFO.type && (
                  <FormattedMessage
                    id="SamplingRequest.btnLabelRequestContactUs"
                    defaultMessage="SEND"
                  />
                )}
              </p>
            ) : (
              <p className="m-0 p-0 text-white">
                <FormattedMessage
                  id="SamplingRequest.btnLabelLoading"
                  defaultMessage="Sending..."
                />
              </p>
            )}
          </button>
        </div>
        {errorCreateOrder?.networkError?.statusCode === 400 && (
          <span className="form-error-message form-error-message--color-red">
            <small>{errorCreateOrder.networkError.result.errors[0].message}</small>
          </span>
        )}
        {errorlogDownload && (
          <span className="form-error-message form-error-message--color-red">
            <small>{errorlogDownload}</small>
          </span>
        )}
      </div>
    </form>
  )
}

RequestForm.propTypes = {
  handleCallbackToShowThankYouMessage: PropTypes.func.isRequired
}

export default RequestForm
