import PropTypes from "prop-types"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import classNames from "classnames"
import { useStateMachine } from "little-state-machine"
import { KIZUNA_DESIGNS } from "modules/kizuna/components/Designs/constants"
import { updateAction } from "app/store"
import { SAMPLES } from "modules/kizuna/components/Designs/components/ProductsSamples/constants/samples"
import "./styles/products-list.scss"
import { KIZUNA_REFERENCES } from "./constants"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

const ProductsList = ({ isFiltered = false }) => {
  const navigate = useNavigate()
  const { product } = useParams()
  const { state, actions } = useStateMachine({ updateAction })
  const isMobile = useMediaQuery(SM)

  const handleClickInADesignTitle = (design) => {
    const sampleDetails = SAMPLES.filter((designDetails) => designDetails.designGroup === design)[0]
      ?.colors[0]

    actions.updateAction({
      showUnderlined: {
        checker: !!(design === KIZUNA_DESIGNS[0]),
        artisan: !!(design === KIZUNA_DESIGNS[1]),
        tweed: !!(design === KIZUNA_DESIGNS[2])
      },
      productDetails: {
        title: design,
        sampleDetails
      }
    })

    const access = document.getElementById("designs")
    if (access) {
      access.scrollIntoView({ behavior: "smooth" }, true)
    }

    navigate(`/en-us/kizuna/${design}`)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      actions.updateAction({
        showUnderlined: {},
        sampleCount: []
      })
    })
  }, [actions, state])

  return (
    <div className="design-navigator--wrapper">
      <div className="row g-0">
        <div
          className={classNames({
            "design-navigator--container": true,
            "d-flex": !isFiltered && !isMobile
          })}
        >
          {!isFiltered ? (
            <>
              {KIZUNA_REFERENCES.map(({ id, name, img }) => (
                <div
                  key={`key_${id}`}
                  className="col-12 col-md-4 card-pattern"
                  style={{
                    backgroundImage: `url(${img})`
                  }}
                  onClick={() => handleClickInADesignTitle(id)}
                >
                  <div className="overlay" />
                  <h2 className="card-title">{name}</h2>
                  <p className="card-label">View Colors</p>
                </div>
              ))}
            </>
          ) : (
            <>
              <h1 className="basic-title">View More Kizuna Designs</h1>
              <div className="filtered-references--container">
                {KIZUNA_REFERENCES.filter(({ id }) => id !== product).map(({ id, name, img }) => (
                  <div
                    key={`key_${id}`}
                    className="col-12 col-md-6 card-pattern"
                    style={{
                      backgroundImage: `url(${img})`
                    }}
                    onClick={() => handleClickInADesignTitle(id)}
                  >
                    <div className="overlay" />
                    <h2 className="card-title">{name}</h2>
                    <p className="card-label">View Colors</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ProductsList.propTypes = {
  isFiltered: PropTypes.bool
}

export default ProductsList
