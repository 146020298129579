import { useNavigate } from "react-router-dom"
import mainBanner from "./img/main-banner.jpg"
import "./styles/cleaning-guide-marine.scss"
import { getCurrentLanguageAndInstanceCode } from "services/instances"

const LANDING_CLEANING_GUIDE_MARINE_URL = "cleaning-guide-marine"
const CLEANING_GUIDE_MARINE_GENERAL_URL = "general"
const CLEANING_GUIDE_MARINE_CHIL_URL = "chil"

const CleaningGuideMarine = () => {
  const navigate = useNavigate()
  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const handleGuideRedirect = (param) =>
    navigate(`/${lang}-${instance}/${LANDING_CLEANING_GUIDE_MARINE_URL}/${param}`)

  return (
    <div
      id="home-slide"
      className="home-slide main-container--cleaning-sm"
      style={{
        backgroundImage: `url(${mainBanner})`
      }}
    >
      <div className="logo-container--cleaning-sm">
        <img
          className="img-fluid"
          width={240}
          src="https://static.spradling.group/uploads/instances/3/logo-spradling.png"
        />
      </div>
      <div className="home-slide__content home-slide__content--cleaning-sm home-slide__content--center">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h1 className="title--cleaning-sm">
            Welcome to Spradling Marine
            <br />
            Cleaning Guide
          </h1>
          <div className="btns-container--cleaning-sm">
            <button
              className="btn btn-light my-2 my-lg-0 mx-3"
              onClick={() => handleGuideRedirect(CLEANING_GUIDE_MARINE_GENERAL_URL)}
            >
              General Cleaning Guide
            </button>
            <button
              className="btn btn-light my-2 my-lg-0 mx-3"
              onClick={() => handleGuideRedirect(CLEANING_GUIDE_MARINE_CHIL_URL)}
            >
              Chil™ Cleaning Guide
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CleaningGuideMarine
