import { FormattedMessage } from "react-intl"
import { DO_AND_DO_NOT_ITEM_CLEANING_CARE } from "./doAndDoNots"
import { CLEAING_PROCEDURE_ITEM_CLEANING_CARE } from "./cleaningProcedure"
import { KEEP_IN_MIND_ITEM_CLEANING_CARE } from "./keepInMind"
import {
  BIMI_TOPS_COVERS,
  BIMI_TOPS_COVERS_INFO,
  WOVEN_FLOORING,
  WOVEN_FLOORING_INFO
} from "../../General/constants"
import boats from "modules/cleaning-guide-marine/components/Layout/imgs/1-boats.jpg"
import banner from "modules/cleaning-guide-marine/components/Banner/img/banner.jpg"

const CLEANING_CARE = "Chil Vinyl"

export const NAV_ITEMS = [CLEANING_CARE, BIMI_TOPS_COVERS, WOVEN_FLOORING]

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: banner,
  title: (
    <FormattedMessage
      id="CleaningMarine.Chill.Banner.title"
      defaultMessage="Chil™ Cleaning Guide"
    />
  ),
  description: (
    <FormattedMessage
      id="CleaningMarine.General.Banner.description"
      defaultMessage="By following the recommendations in this guide, you’ll{br}keep all your coated fabrics looking good, preventing
      premature wear and tear."
      values={{
        br: <br />
      }}
    />
  )
}

const BOAT_SEATS_INFO = {
  id: CLEANING_CARE,
  titleDesktop: CLEANING_CARE,
  titleMobile: CLEANING_CARE,
  img: boats,
  doAndDoNots: DO_AND_DO_NOT_ITEM_CLEANING_CARE,
  cleaningProcedures: CLEAING_PROCEDURE_ITEM_CLEANING_CARE,
  keepInMind: KEEP_IN_MIND_ITEM_CLEANING_CARE
}

export const GENERAL_INFO_SECTIONS = {
  [CLEANING_CARE]: BOAT_SEATS_INFO,
  [BIMI_TOPS_COVERS]: BIMI_TOPS_COVERS_INFO,
  [WOVEN_FLOORING]: WOVEN_FLOORING_INFO
}
