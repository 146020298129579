/* eslint-disable react/prop-types */
import { NO_OP } from "consts"
import { defineMessages, FormattedMessage } from "react-intl"
import TooltipModal from "modules/claims/components/StepForm/Step0/components/TooltipModal"
import nameColorProduct from "modules/claims/components/StepForm/Step0/img/name-color-product.jpeg"
import quantityProductChairs from "modules/claims/components/StepForm/Step0/img/chairs.png"
import quantityProductRuler from "modules/claims/components/StepForm/Step0/img/ruler.jpg"
import productInvoice from "modules/claims/components/StepForm/Step0/img/product-invoice.png"
import batchProduct from "modules/claims/components/StepForm/Step0/img/batch-product.jpeg"

export const GENERIC_MSGS = defineMessages({
  typeAnswer_placeholder: {
    id: "Form.placeholder.typeAnswer",
    description: "placeholder type answer for input",
    defaultMessage: "Type your answer here..."
  },
  choose_placeholder: {
    id: "Form.placeholder.choose",
    description: "placeholder choose for select",
    defaultMessage: "Choose..."
  },
  yes: {
    id: "Form.value.yes",
    description: "value yes for boolean field",
    defaultMessage: "Yes"
  },
  no: {
    id: "Form.value.no",
    description: "value no for boolean field",
    defaultMessage: "No"
  },
  date: {
    id: "Form.value.date",
    description: "value for date field",
    defaultMessage: "DD-MM-YYYY"
  }
})

export const BASIC_BOOLEAN_OPTIONS = [
  {
    id: "yes",
    label: GENERIC_MSGS.yes,
    value: "yes"
  },
  {
    id: "no",
    label: GENERIC_MSGS.no,
    value: "no"
  }
]

export const NAME_AND_COLOR_TOOLTIP = ({ isOpen = false, handleToggable = NO_OP }) => (
  <TooltipModal
    isOpen={isOpen}
    handleToggable={handleToggable}
    img={nameColorProduct}
    title={
      <div className="mb-3">
        <FormattedMessage
          id="Customer.tooltip.title"
          description="tooltip title of modal Customer Number"
          defaultMessage="Where you can find your {item}?"
          values={{ item: "Name & colour of the claimed product" }}
        />
      </div>
    }
  />
)

export const QUANTITY_TOOLTIP = ({ isOpen = false, handleToggable = NO_OP }) => (
  <TooltipModal
    isOpen={isOpen}
    handleToggable={handleToggable}
    title={
      <FormattedMessage
        id="Customer.tooltip.title"
        description="tooltip title of modal Customer Number"
        defaultMessage="Where you can find your {item}?"
        values={{ item: "Quantity of the claimed product or affected pieces" }}
      />
    }
  >
    <div className="d-flex justify-content-center align-items-center mt-5">
      <span>
        <img className="img-fluid w-50" src={quantityProductRuler} alt="tooltip" />
        <p>
          <FormattedMessage
            id="Claims.Registration.check.quantity.quantityMeters"
            description="tooltip ruler, Quantity of linear meters"
            defaultMessage="Quantity of linear meters"
          />
        </p>
      </span>
      <span>
        <img className="img-fluid w-50" src={quantityProductChairs} alt="tooltip" />
        <p>
          <FormattedMessage
            id="Claims.Registration.check.quantity.AffectedPieces"
            description="tooltip ruler, Quantity of linear meters"
            defaultMessage="# affected pieces (cushions / seats)"
          />
        </p>
      </span>
    </div>
  </TooltipModal>
)

export const CUSTOMER_NUMBER_TOOLTIP = ({ isOpen = false, handleToggable = NO_OP }) => (
  <TooltipModal
    isOpen={isOpen}
    handleToggable={handleToggable}
    img={productInvoice}
    title={
      <FormattedMessage
        id="Customer.tooltip.title"
        description="tooltip title of modal Customer Number"
        defaultMessage="Where you can find your {item}?"
        values={{ item: "Customer number" }}
      />
    }
    description={
      <FormattedMessage
        id="Customer.tooltip.description"
        description="tooltip description of modal Customer Number"
        defaultMessage="Indicated on delivery papers or Spradling<sup>®</sup> invoices"
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    }
  />
)

export const BATCH_TOOLTIP = ({ isOpen = false, handleToggable = NO_OP }) => (
  <TooltipModal
    isOpen={isOpen}
    handleToggable={handleToggable}
    img={batchProduct}
    title={
      <div className="mb-4">
        <FormattedMessage
          id="Customer.tooltip.title"
          description="tooltip title of modal Customer Number"
          defaultMessage="Where you can find your {item}?"
          values={{ item: "Production Batch No. of the material" }}
        />
      </div>
    }
  />
)
