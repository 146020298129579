import { useNavigate } from "react-router-dom"
import { useIntl, FormattedMessage } from "react-intl"
import classNames from "classnames"
import { useForm, useWatch } from "react-hook-form"
import { useStateMachine } from "little-state-machine"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Layout from "modules/claims/components/Layout"
import InputText from "components/InputMUI/Text"
import Select from "components/InputMUI/Select"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { updateAction } from "utils/littleStateMachine"
import { GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import {
  APPLICATION_OF_MATERIAL_OPTIONS,
  CURRENT_CONDITION_FOR_INSTALLED,
  CURRENT_CONDITION_FOR_TRANSFORMED,
  CURRENT_CONDITION_MSGS,
  INSTALLED_IN_OPTIONS,
  CONDITION_OF_MATERIAL_OPTIONS,
  WHEN_DETECTS_DIFFERENCE_OPTIONS
} from "./constants"
import { MATERIAL_CURRENT_STATE } from "../Step2/constants"
import "../styles/step-form.scss"
import RadioButton from "components/InputMUI/RadioButton"
import DateInput from "components/InputMUI/DateInput"
import { GET_BACKGROUND_IMAGE_ACCORD_TO_REASON } from "../../Layout/constants"

const inputPlaceholder = GENERIC_MSGS.typeAnswer_placeholder
const selectPlaceholder = GENERIC_MSGS.choose_placeholder
const datePlaceholder = GENERIC_MSGS.date

const Step3 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  const { state: { conditions } } = useStateMachine()

  const defaultValues = { ...state }
  const schema = yup.object(
    state.conditions !== MATERIAL_CURRENT_STATE.TRANSFORMED
      ? CURRENT_CONDITION_FOR_INSTALLED(intl)
      : CURRENT_CONDITION_FOR_TRANSFORMED(intl)
  )

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const applicationValue = useWatch({ control, name: "application" })
  const conditionsOfMaterialValue = useWatch({ control, name: "conditionsOfMaterial" })

  const onSubmit = (data) => {
    actions.updateAction(data)
    navigate(`../${lang}-${instance}/claims/step-4`, { replace: true })
  }

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.currentCondition.title"
          description="section of current condition title"
          defaultMessage="{condition} Condition"
          values={{
            condition: state.conditions
          }}
        />
      }
      description={
        state.conditions !== MATERIAL_CURRENT_STATE.TRANSFORMED
          ? intl.formatMessage(CURRENT_CONDITION_MSGS.installed)
          : intl.formatMessage(CURRENT_CONDITION_MSGS.transformed)
      }
      alignDescription="center"
      backgroundImage={GET_BACKGROUND_IMAGE_ACCORD_TO_REASON[conditions].backgroundImage}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          name="application"
          control={control}
          label={CURRENT_CONDITION_MSGS.applicationOfMaterial}
          placeholder={selectPlaceholder}
          options={APPLICATION_OF_MATERIAL_OPTIONS}
          defaultValue={state.application}
          error={errors.application?.message}
          isMulti
        />
        {/**
         * if exist value other as selected option
         * validation value and last item value of array of objects of APPLICATION_OF_MATERIAL_OPTIONS
         * because the last item is options Other
         */}
        {applicationValue?.some(
          ({ value }) =>
            value ===
            APPLICATION_OF_MATERIAL_OPTIONS[APPLICATION_OF_MATERIAL_OPTIONS.length - 1].value
        ) && (
          <InputText
            name="describeApplicationMaterial"
            register={register}
            label={CURRENT_CONDITION_MSGS.describeApplicationMaterial}
            placeholder={inputPlaceholder}
            defaultValue={state.describeApplicationMaterial}
            error={errors.describeApplicationMaterial?.message}
          />
        )}
        {state.conditions !== MATERIAL_CURRENT_STATE.ORIGINAL && (
          <>
            <Select
              name="whenDetectDifference"
              control={control}
              label={CURRENT_CONDITION_MSGS.whenDetectsDifference}
              placeholder={GENERIC_MSGS.choose_placeholder}
              options={WHEN_DETECTS_DIFFERENCE_OPTIONS}
              defaultValue={state.whenDetectDifference}
              error={errors.whenDetectDifference?.message}
            />
            <Select
              name="conditionsOfMaterial"
              control={control}
              label={CURRENT_CONDITION_MSGS.conditionsOfMaterial}
              placeholder={GENERIC_MSGS.choose_placeholder}
              options={CONDITION_OF_MATERIAL_OPTIONS}
              defaultValue={state.conditionsOfMaterial}
              error={errors.conditionsOfMaterial?.message}
            />
            { conditionsOfMaterialValue &&
                conditionsOfMaterialValue === CONDITION_OF_MATERIAL_OPTIONS[CONDITION_OF_MATERIAL_OPTIONS.length - 1].value &&
              <InputText
                name="describeConditionsOfMaterial"
                register={register}
                label={CURRENT_CONDITION_MSGS.describeConditionsOfMaterial}
                placeholder={inputPlaceholder}
                defaultValue={state.describeConditionsOfMaterial}
                error={errors.describeConditionsOfMaterial?.message}
              />
            }
          </>
        )}
        {state.conditions !== MATERIAL_CURRENT_STATE.TRANSFORMED ? (
          <>
            <RadioButton
              name="installedIn"
              register={register}
              label={CURRENT_CONDITION_MSGS.installedIn}
              options={INSTALLED_IN_OPTIONS}
              defaultValue={state.installedIn}
              error={errors.installedIn?.message}
            />
            <InputText
              name="placeWhereIsInstalled"
              register={register}
              label={CURRENT_CONDITION_MSGS.placeWhereIsInstalled}
              placeholder={CURRENT_CONDITION_MSGS.placeWhereIsInstalledPlaceholder}
              defaultValue={state.placeWhereIsInstalled}
              error={errors.placeWhereIsInstalled?.message}
            />
            <DateInput
              name="dateOfPurchase"
              control={control}
              label={CURRENT_CONDITION_MSGS.dateOfPurchase}
              placeholder={datePlaceholder}
              defaultValue={state.dateOfPurchase}
              error={errors.dateOfPurchase?.message}
            />
            <DateInput
              name="dateOfMaterialInstalled"
              control={control}
              label={CURRENT_CONDITION_MSGS.dateMaterialInstalled}
              placeholder={datePlaceholder}
              defaultValue={state.dateOfMaterialInstalled}
              error={errors.dateOfMaterialInstalled?.message}
            />
          </>
        ) : (
          <>
            <DateInput
              name="dateOfPurchase"
              control={control}
              label={CURRENT_CONDITION_MSGS.dateOfPurchase}
              placeholder={datePlaceholder}
              defaultValue={state.dateOfPurchase}
              error={errors.dateOfPurchase?.message}
            />
            <DateInput
              name="dateOfMaterialTransformed"
              control={control}
              label={CURRENT_CONDITION_MSGS.dateMaterialTransformed}
              placeholder={datePlaceholder}
              defaultValue={state.dateOfMaterialTransformed}
              error={errors.dateOfMaterialTransformed?.message}
            />
          </>
        )}
        <div>
          <button
            className={classNames({
              "btn btn-dark text-uppercase": true,
              disabled: Object.values(errors).length
            })}
            type="submit"
          >
            {intl.formatMessage(BUTTON_MESSAGES.ok)}
          </button>
        </div>
        <ProgressBar
          progress={40}
          handleNext={handleSubmit(onSubmit)}
          RoutePrev={`../${lang}-${instance}/claims/step-2`}
        />
      </form>
    </Layout>
  )
}

export default Step3
