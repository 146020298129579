import Slider from "../../components/Carousel"
import Footer from "../../components/Footer"
import Video from "../../components/Video"
import Header from "../../components/Header"
import { DROP_DOWN_ITEMS } from "../../components/Header/constants"
import Hero from "../../components/Hero"
import {
  MAIN_VIDEO_URL,
  CONCEPTS_SLIDER,
  DESIGNS_INFO,
  POSTER_KIZUNA_COLLECTION,
  STORY_VIDEO_URL
} from "./constants"
import RequestsSection from "../../components/RequestsSection"
import { REQUEST_SAMPLES_INFO } from "../../components/RequestsSection/utils"
import useRequestModal from "../../components/Footer/useRequestModal"
import { useStateMachine } from "little-state-machine"
import ProductsList from "modules/kizuna/components/Designs/components/ProductsList"
import ReactPlayer from "react-player/youtube"
import "../../components/Carousel/styles/carousel.scss"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import useOrderBox from "modules/kizuna/components/OrderInfo/useOrderBox"
import OrderInfo from "modules/kizuna/components/OrderInfo"

const Home = () => {
  const isMobile = useMediaQuery(LG)
  // WARNING: for handle Order Box and Form Request
  const { state } = useStateMachine()
  const {
    modalContext,
    toggable: toggableForm,
    toggle: toggleForm,
    handleClickInRequest,
    handleOrderCatalogue
  } = useRequestModal()
  const { showInformationBox, setShowInformationBox, handleShowInformationBox } = useOrderBox()

  return (
    <>
      <Header />
      <Hero
        id="#home"
        bannerVideo={MAIN_VIDEO_URL}
        handleOrderCatalogue={handleOrderCatalogue}
        handleShowInformationBox={handleShowInformationBox}
      />
      <ProductsList />
      <Video id={DROP_DOWN_ITEMS[5].id} poster={POSTER_KIZUNA_COLLECTION} fromYoutube>
        <div style={isMobile ? { widht: "100%" } : { width: 360 }}>
          <ReactPlayer url={STORY_VIDEO_URL} loop controls />
        </div>
      </Video>
      <div className="my-3">
        <Slider id={DROP_DOWN_ITEMS[3].id} slides={CONCEPTS_SLIDER} />
      </div>
      <section id={DROP_DOWN_ITEMS[4].id} className="section-design--container">
        <div
          className="section-design--img"
          style={{
            backgroundImage: `url(${DESIGNS_INFO.img})`
          }}
        >
          <div className="col-12 d-flex align-items-center">
            <div className="slider-info--container">
              <h1 className="text-white pb-2 mb-4">{DESIGNS_INFO.title}</h1>
              <p className="section-design--desc">{DESIGNS_INFO.description}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer
        handleClickInRequest={handleClickInRequest}
        handleOrderCatalogue={handleOrderCatalogue}
        handleShowInformationBox={handleShowInformationBox}
      />
      {state?.sampleCount?.length > 0 && (
        <OrderInfo
          showBox={showInformationBox}
          toggleShowBox={setShowInformationBox}
          toggle={() => handleClickInRequest(REQUEST_SAMPLES_INFO)}
        />
      )}
      <RequestsSection modalContext={modalContext} toggable={toggableForm} toggle={toggleForm} />
    </>
  )
}

export default Home
