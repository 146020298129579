import { useEffect, useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { scroller } from "react-scroll"
import ProductsSamplesDetails from "modules/kizuna/components/Designs/components/ProductsSamplesDetails"
import Footer from "modules/kizuna/components/Footer"
import Header from "modules/kizuna/components/Header"
import Hero from "modules/kizuna/components/Hero"
import ProductsList from "modules/kizuna/components/Designs/components/ProductsList"
import { KIZUNA_REFERENCES } from "modules/kizuna/components/Designs/components/ProductsList/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowLeft, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import useOrderBox from "modules/kizuna/components/OrderInfo/useOrderBox"
import OrderInfo from "modules/kizuna/components/OrderInfo"
import { useStateMachine } from "little-state-machine"
import useRequestModal from "modules/kizuna/components/Footer/useRequestModal"
import RequestsSection from "modules/kizuna/components/RequestsSection"
import { REQUEST_SAMPLES_INFO } from "modules/kizuna/components/RequestsSection/utils"

const ProductDetailsPage = () => {
  const navigation = useNavigate()
  const { product: design } = useParams()
  const isMobile = useMediaQuery(LG)
  const PRODUCT_INFO = useMemo(() => KIZUNA_REFERENCES.find(({ id }) => id === design), [design])
  // WARNING: for handle Order Box and Form Request
  const { state } = useStateMachine()
  const {
    modalContext,
    toggable: toggableForm,
    toggle: toggleForm,
    handleClickInRequest,
    handleOrderCatalogue
  } = useRequestModal()
  const { showInformationBox, setShowInformationBox, handleShowInformationBox } = useOrderBox()

  const handleGoBack = () => {
    navigation(-1)
  }

  useEffect(() => {
    scroller.scrollTo("banner-design", {
      duration: 100,
      delay: 100,
      smooth: true,
      spy: true
    })
  }, [design])

  return (
    <main>
      <Header />
      <Hero
        id="banner-design"
        bannerImg={PRODUCT_INFO.banner}
        bannerTitile={design}
        bannerDescription={PRODUCT_INFO.bannerDescription}
        handleOrderCatalogue={handleOrderCatalogue}
        handleShowInformationBox={handleShowInformationBox}
      />
      <div id="info-section" className="container">
        <div
          className="d-flex align-items-center mt-4 mt-lg-5 mb-lg-3 cursor-pointer"
          onClick={() => handleGoBack()}
        >
          <FontAwesomeIcon
            className=""
            style={{ width: 20, height: 20 }}
            icon={!isMobile ? faLongArrowLeft : faAngleLeft}
            color="gray"
            fixedWidth
          />
          <p className="m-0 ms-1 ms-lg-3">Go Back</p>
        </div>
        <div id="container-samples-detail" className="row">
          <ProductsSamplesDetails
            design={design}
            showInformationBox={showInformationBox}
            setShowInformationBox={setShowInformationBox}
            handleShowInformationBox={handleShowInformationBox}
            toggleForm={toggleForm}
          />
        </div>
      </div>
      <ProductsList isFiltered />
      <Footer
        handleClickInRequest={handleClickInRequest}
        handleOrderCatalogue={handleOrderCatalogue}
        handleShowInformationBox={handleShowInformationBox}
      />
      {state?.sampleCount?.length > 0 && (
        <OrderInfo
          showBox={showInformationBox}
          toggleShowBox={setShowInformationBox}
          toggle={() => handleClickInRequest(REQUEST_SAMPLES_INFO)}
        />
      )}
      <RequestsSection modalContext={modalContext} toggable={toggableForm} toggle={toggleForm} />
    </main>
  )
}

export default ProductDetailsPage
