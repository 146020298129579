import PropTypes from "prop-types"
import classNames from "classnames/bind"
import Menu from "./components/Menu"
import scssVars from "assets/styles/_variables.scss"

import { useState, useEffect } from "react"
import { instanceQuery } from "components/Footer/query"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import "./styles/header.scss"

const Header = ({
  className,
  items: itemsProp,
  objItems,
  isTransparent = false,
  isSticky = true
}) => {
  const [scrolled, setScrolled] = useState(false)
  const { data } = useQuery(instanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    }
  })
  useEffect(() => {
    isScrolled()
  })

  const isScrolled = () => {
    if (isSticky) {
      window.onscroll = () => {
        const headerHeight = parseFloat(scssVars.headerHeight.substring(0, 4)) / (1 / 16)
        if (window.scrollY > headerHeight) {
          setScrolled(true)
        } else if (window.scrollY < headerHeight) {
          setScrolled(false)
        }
      }
    }
  }

  return (
    <header
      className={classNames({
        "fixed-top pt-2": isSticky,
        "header-static": !isSticky,
        "bg-transparent": isTransparent && !scrolled,
        [className]: className
      })}
    >
      {!itemsProp ? (
        <Menu logo={data && data.instance.logo} items={null} objItems={objItems} />
      ) : (
        <Menu logo={data && data.instance.logo} items={itemsProp} objItems={null} />
      )}
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(PropTypes.object),
  isSticky: PropTypes.bool,
  isTransparent: PropTypes.bool
}

export default Header
