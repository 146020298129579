import PropTypes from "prop-types"
import { Link } from "react-scroll"
import classNames from "classnames/bind"
import BurgerBtn from "components/Header/components/Menu/components/BurgerBtn"
import useToggle from "hooks/useToggle"
import "./styles/navbar.scss"
import RenderObject from "./components/RenderObject"
import spradlingMarineLogo from "./img/logo-spradling-marine.png"

export const Menu = ({ logo, items = [], objItems = [], sectionId, handleChangeSection, handleChandeIdSection }) => {
  const [toggable, setToggable] = useToggle()

  const updateSection = (item) => {
    handleChandeIdSection({
      id: item,
      clickInHeader: true
    })
    handleChangeSection(item)
  }

  const handleRenderArray = () =>
    items.map((item) => {
      return <li className="main-menu-sections__section" key={`key_${item}`}>
        <div className="text-capitalize">
          <Link
            className="header-menu-button"
            to={sectionId}
            activeClass={sectionId === item && "header-menu-button--active"}
            duration={100}
            offset={-80}
            smooth
            spy
            onClick={() => updateSection(item)}
          >
            {item}
          </Link>
        </div>
      </li>
    })

  return (
    <div className="container">
      <div className="row g-0">
        <div className="col-2 col-md-10 order-md-1">
          <BurgerBtn toggable={toggable} setToggable={setToggable} />
          <div
            className={classNames({
              "header-main-menu": true,
              "header-main-menu--open": toggable
            })}
          >
            <ul
              className={classNames({
                "main-menu-sections": true,
                "main-menu-sections--open": toggable
              })}
            >
              {!items ? <RenderObject items={objItems} /> : handleRenderArray()}
            </ul>
          </div>
        </div>
        <div className="col-8 ps-3 ps-md-0 col-md-2 order-md-0">
          <Link to="home-slide" className="logo cursor-pointer" duration={100} smooth={true}>
              <img
                className="img-fluid"
                src={spradlingMarineLogo}
                alt="Spradling Group"
                title="Spradling Group"
                width="150px"
                height="50px"
              />
          </Link>
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      externalLink: PropTypes.bool,
      href: PropTypes.string
    })
  ),
  sectionId: PropTypes.string,
  handleChangeSection: PropTypes.func,
  handleChandeIdSection: PropTypes.func
}

export default Menu
