import classNames from "classnames"
import spradlingLogoWhite from "./assets/img/logo-spradling-white.png"
import spradlingLogoGray from "./assets/img/spradling-logo-gray.svg"
import "./assets/styles/footer.scss"
import { FOOTER_ITEMS } from "./constants/footerItems"
import { LG } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"
import spradlingMarineLogo from "./assets/img/logo-spradling-marine.png"

const Footer = () => {
  const isMobile = useMediaQuery(LG)

  return (
    <footer
      className={classNames({
        "text-light": true,
        "footer-cleaning-guide": true
      })}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between py-3">
          <div className="col-12 col-md-8 order-md-1">
            <ul className="row text-center text-md-end container-cleaning-produre footer-sections mb-0 p-0">
              {FOOTER_ITEMS.map(({ label, value }) => (
                <li key={`key_${label}`} className="col-12 col-md-12 py-2">
                  <div>
                    <span className={classNames({
                      "m-0 fs-4": true,
                      "font-color-footer": true
                    })}>{value}</span>
                    <a
                      className={classNames({
                        "ms-2 fs-3": true,
                        "cleaning-sm-footer-link": true
                      })}
                      href="https://spradling.group/en-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: "600" }}
                    >
                      here
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-md-2 m-2 ms-3 order-md-0">
            <div className="logo-section">
              <img
                className="logo-instance"
                src={spradlingMarineLogo}
                alt="Spradling Group"
                title="Spradling Group"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
