import * as yup from "yup"
import { defineMessages } from "react-intl"
import { VALIDATION_MESSAGES } from "utils/forms"

export const CURRENT_CONDITION_FOR_GLOBAL = (intl) => ({
  application: yup
    .array(
      yup.object().shape({
        id: yup.string(),
        label: yup.string(),
        value: yup.string()
      })
    )
    .test(
      "test_application_multiSelect",
      intl.formatMessage(VALIDATION_MESSAGES.required),
      (val) => {
        return val?.length
      }
    ),
  dateOfPurchase: yup.date().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  whenDetectDifference: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  conditionsOfMaterial: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const CURRENT_CONDITION_FOR_TRANSFORMED = (intl) => ({
  ...CURRENT_CONDITION_FOR_GLOBAL(intl),
  dateOfMaterialTransformed: yup.date().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const CURRENT_CONDITION_FOR_INSTALLED = (intl) => ({
  ...CURRENT_CONDITION_FOR_GLOBAL(intl),
  installedIn: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  placeWhereIsInstalled: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  dateOfMaterialInstalled: yup.date().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const CURRENT_CONDITION_MSGS = defineMessages({
  transformed: {
    id: "Claims.CurrentCondition.description.transformed",
    description: "description in transformed in on current condition",
    defaultMessage:
      "Material has been transformed but not yet been installed (e.g. seats, covers, cushions or components have been manufactured with the material but not been installed)"
  },
  installed: {
    id: "Claims.CurrentCondition.description.installed",
    description: "description in installed in on current condition",
    defaultMessage:
      "Material has been transformed and has been installed (e.g. on a chair in a restaurant, on a boat, etc.)"
  },
  applicationOfMaterial: {
    id: "Claims.CurrentCondition.applicationOfMaterial",
    description: "field application of material on current condition",
    defaultMessage: "What is/are the final application(s) of the material(s)?"
  },
  describeApplicationMaterial: {
    id: "Claims.CurrentCondition.otherApplicationOfMaterial",
    description: "field application of material on current condition",
    defaultMessage: "Please describe"
  },
  installedIn: {
    id: "Claims.CurrentCondition.installedIn",
    description: "field installed in on current condition",
    defaultMessage: "Where is the material installed?"
  },
  placeWhereIsInstalled: {
    id: "Claims.CurrentCondition.placeWhereIsInstalled",
    description: "field place where is installed on current condition",
    defaultMessage: "Please indicate the place where the material is installed"
  },
  placeWhereIsInstalledPlaceholder: {
    id: "Claims.CurrentCondition.placeWhereIsInstalled.placeholder",
    description: "field place where is installed on current condition",
    defaultMessage: "Restaurant, Hotel, Office, Boat, etc."
  },
  dateOfPurchase: {
    id: "Claims.CurrentCondition.dateOfPurchase",
    description: "field date of purchase on current condition",
    defaultMessage: "Please indicate the date of purchase"
  },
  dateMaterialTransformed: {
    id: "Claims.CurrentCondition.dateMaterialTransformed",
    description: "field date of material transformed on current condition",
    defaultMessage: "When was the material transformed?"
  },
  dateMaterialInstalled: {
    id: "Claims.CurrentCondition.dateMaterialInstalled",
    description: "field date of material installed on current condition",
    defaultMessage: "Since when is material installed?"
  },
  whenDetectsDifference: {
    id: "Claims.VisualDefects.whenDetectsDifference",
    description: "option when decets difference on visual defects",
    defaultMessage: "When is the difference detected?"
  },
  conditionsOfMaterial: {
    id: "Claims.VisualDefects.conditionsOfMaterial",
    description: "option condition of material on visual defects",
    defaultMessage: "Under what conditions is the material used in the customer's process?"
  },
  describeConditionsOfMaterial: {
    id: "Claims.CurrentCondition.otherApplicationOfMaterial",
    description: "field application of material on current condition",
    defaultMessage: "Please describe"
  }
})

const APPLICATION_OF_MATERIAL_OPTIONS_MSGS = defineMessages({
  chair: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.chair",
    description: "option seat/chair in application of material on current condition",
    defaultMessage: "Seat/chair"
  },
  cushion: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.cushion",
    description: "option cushion in application of material on current condition",
    defaultMessage: "Cushion"
  },
  wallcovering: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.wallcovering",
    description: "option wallcovering in application of material on current condition",
    defaultMessage: "Wallcovering"
  },
  partitionWall: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.partitionWall",
    description: "option partitionWall in application of material on current condition",
    defaultMessage: "PartitionWall"
  },
  headliners: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.headliners",
    description: "option headliners in application of material on current condition",
    defaultMessage: "Headliners/ ceilings"
  },
  dashboard: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.dashboard",
    description: "option dashboard in application of material on current condition",
    defaultMessage: "Dashboard/ instrument panel"
  },
  headboards: {
    id: "Claims.CurrentCondition.applicationOfMaterial.options.headboards",
    description: "option headboards in application of material on current condition",
    defaultMessage: "Dashboard/ headboards"
  },
  other: {
    id: "Claims.options.other",
    description: "option other on claim",
    defaultMessage: "Other"
  }
})

export const APPLICATION_OF_MATERIAL_OPTIONS = [
  {
    id: "chair",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.chair,
    value: "set/chair"
  },
  {
    id: "cushion",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.cushion,
    value: "cushion"
  },
  {
    id: "wallcovering",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.wallcovering,
    value: "wallcovering"
  },
  {
    id: "partitionWall",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.partitionWall,
    value: "partition wall"
  },
  {
    id: "headliners",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.headliners,
    value: "headliners/ceilings"
  },
  {
    id: "dashboard",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.dashboard,
    value: "dashboard/instrument panel"
  },
  {
    id: "headboards",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.headboards,
    value: "headboards/bedheads"
  },
  {
    id: "other",
    label: APPLICATION_OF_MATERIAL_OPTIONS_MSGS.other,
    value: "other"
  }
]

const INSTALLED_IN_OPTIONS_MSGS = defineMessages({
  indoor: {
    id: "Claims.CurrentCondition.installedIn.indoor",
    description: "option indoor in installed in on current condition",
    defaultMessage: "Indoor"
  },
  outdoor: {
    id: "Claims.CurrentCondition.installedIn.outdoor",
    description: "option outdoor in installed in on current condition",
    defaultMessage: "Outdoor"
  }
})

export const INSTALLED_IN_OPTIONS = [
  {
    id: "indoor",
    label: INSTALLED_IN_OPTIONS_MSGS.indoor,
    value: "indoor"
  },
  {
    id: "outdoor",
    label: INSTALLED_IN_OPTIONS_MSGS.outdoor,
    value: "outdoor"
  }
]

const WHEN_DETECTS_DIFFERENCE_OPTIONS_MSGS = defineMessages({
  beforeMaterialIsProcessed: {
    id: "Claims.VisualDefects.whenDetectsDifference.options.beforeMaterialIsProcessed",
    description: "option before material is processed in when detects difference on visual defects",
    defaultMessage: "Before material is processed/transformed"
  },
  afterMaterialIsProcessed: {
    id: "Claims.VisualDefects.whenDetectsDifference.options.afterMaterialIsProcessed",
    description: "option after material is processed in when detects difference on visual defects",
    defaultMessage: "After material is processed/transformed"
  }
})

export const WHEN_DETECTS_DIFFERENCE_OPTIONS = [
  {
    id: "beforeMaterialIsProcessed",
    label: WHEN_DETECTS_DIFFERENCE_OPTIONS_MSGS.beforeMaterialIsProcessed,
    value: "before material is processed/transformed"
  },
  {
    id: "afterMaterialIsProcessed",
    label: WHEN_DETECTS_DIFFERENCE_OPTIONS_MSGS.afterMaterialIsProcessed,
    value: "after material is processed/transformed"
  }
]

const CONDITION_OF_MATERIAL_OPTIONS_MSGS = defineMessages({
  upholstered: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.upholstered",
    description: "option upholstered in condition of material on visual defects",
    defaultMessage: "Upholstered"
  },
  laminatedFlame: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.laminatedFlame",
    description: "option laminated flame in condition of material on visual defects",
    defaultMessage: "Laminated (flame)"
  },
  laminatedGlue: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.laminatedGlue",
    description: "option laminated glue in condition of material on visual defects",
    defaultMessage: "Laminated (glue/hotmelt)"
  },
  thermoformed: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.thermoformed",
    description: "option thermoformed in condition of material on visual defects",
    defaultMessage: "Thermoformed"
  },
  streched: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.streched",
    description: "option streched in condition of material on visual defects",
    defaultMessage: "Streched"
  },
  injected: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.injected",
    description: "option injected in condition of material on visual defects",
    defaultMessage: "Injected"
  },
  glued: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.glued",
    description: "option glued in condition of material on visual defects",
    defaultMessage: "Glued"
  },
  stapled: {
    id: "Claims.VisualDefects.conditionsOfMaterial.options.stapled",
    description: "option stapled in condition of material on visual defects",
    defaultMessage: "Stapled"
  },
  other: {
    id: "Claims.options.other",
    description: "option other on claim",
    defaultMessage: "Other"
  }
})

export const CONDITION_OF_MATERIAL_OPTIONS = [
  {
    id: "upholstered",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.upholstered,
    value: "upholstered"
  },
  {
    id: "laminatedFlame",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.laminatedFlame,
    value: "laminated(flame)"
  },
  {
    id: "laminatedGlue",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.laminatedGlue,
    value: "laminated(glue,hotmelt)"
  },
  {
    id: "thermoformed",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.thermoformed,
    value: "thermoformed"
  },
  {
    id: "streched",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.streched,
    value: "streched"
  },
  {
    id: "injected",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.injected,
    value: "injected"
  },
  {
    id: "glued",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.glued,
    value: "glued"
  },
  {
    id: "stapled",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.stapled,
    value: "stapled"
  },
  {
    id: "other",
    label: CONDITION_OF_MATERIAL_OPTIONS_MSGS.other,
    value: "other"
  }
]
