import classNames from "classnames/bind"
import Header from "components/Header"
import PropTypes from "prop-types"
import "./assets/styles/claims.scss"
import { useMediaQuery } from "@mui/material"
import { XL } from "consts/breakpoints"
import { STEPS } from "./constants"
import { useLocation } from "react-router-dom"

const Layout = ({ title, description, alignDescription = "left", children, backgroundImage }) => {
  const { pathname } = useLocation()
  const isDesktopScreen = useMediaQuery(XL)
  const heightInSomeSteps = isDesktopScreen && STEPS.some((step) => pathname.includes(step))

  return (
    <>
      <Header isSticky={false} isTransparent />
      <div>
        <div className="d-md-flex">
          <div
            className={classNames({
              "col-12 col-md-6 centered-container hero-container": true,
              "hero-container--description": description,
              "move-background": pathname.includes("step-6")
            })}
            style={{
              backgroundImage: `url(${backgroundImage})`
            }}
          >
            <div className="container">
              <h1
                className={classNames({
                  "hero-title": true,
                  "mb-md-5": description,
                  "hero-title--margin-mobile": description
                })}
              >
                {title}
              </h1>
              <p className={`hero-description px-2 px-md-4 text-${alignDescription}`}>
                {description}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div
              className={classNames({
                "form-container centered-container p-4 p-md-5": true,
                "container-in-some-steps": heightInSomeSteps,
                "container-fullviewport": !pathname.includes("step-2")
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.object.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  alignDescription: PropTypes.oneOf(["left", "center"]),
  children: PropTypes.element.isRequired,
  backgroundImage: PropTypes.string
}

export default Layout
