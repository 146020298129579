import { defineMessages } from "react-intl"

export const DEFECTS_MSGS = defineMessages({
  description: {
    id: "Claims.facialDefects.description",
    description: "field defect for facial defects claim reason",
    defaultMessage: "Please provide a short description of the defect"
  },
  type: {
    id: "Claims.FacialDefects.type",
    description: "option recurrent on Facial Defects",
    defaultMessage: "Is the defect recurrent or punctual?"
  }
})

export const DEFECTS_MSGS_OPTIONS = defineMessages({
  recurrent: {
    id: "Claims.facialDefects.type.options.recurrent",
    description: "option recurrent on Facial Defects",
    defaultMessage: "Recurrent"
  },
  punctual: {
    id: "Claims.FacialDefects.type.options.punctual",
    description: "option punctual on Facial Defects",
    defaultMessage: "Punctual"
  }
})

export const DEFECTS_OPTIONS = [
  {
    id: "recurrent",
    label: DEFECTS_MSGS_OPTIONS.recurrent,
    value: "recurrent"
  },
  {
    id: "punctual",
    label: DEFECTS_MSGS_OPTIONS.punctual,
    value: "punctual"
  }
]
