import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import { FormattedMessage, useIntl } from "react-intl"
import { FaSearch } from "react-icons/fa"
import { GlassMagnifier } from "react-image-magnifiers"
import ProductSpecs from "./components/ProductSpecs"
import SamplesList from "./components/SamplesList"
import { SAMPLES, SAMPLES_SHOW_IN_DESKTOP } from "./constants"
import "./styles/sample-details.scss"
import SamplesCount from "./components/SamplesCount"
import classNames from "classnames"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import { REQUEST_SAMPLES_INFO } from "../RequestsSection/utils"

const SamplesDetails = ({
  nav,
  handleClickInRequest,
  handleAddSample,
  handleOrderCatalogue,
  toggableAccordeon,
  handleToggleAccordeon
}) => {
  const isMobile = useMediaQuery(LG)
  const { state } = useStateMachine()
  const firstSample = SAMPLES_SHOW_IN_DESKTOP[2]

  const imageSrc = state.sampleDetails
    ? state?.sampleDetails?.imgDetail
    : firstSample.imgDetail

  const RenderBtnRequestCatalogue = () => {
    return (
      <div
        className={classNames({
          "text-center mt-2": !isMobile,
          "text-center mt-0 mt-md-3 mb-3": isMobile
        })}
      >
        <button
          className={classNames({
            "btn btn--desc-rv col-auto px-lg-5 mt-lg-4": true
          })}
          onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
        >
          <FormattedMessage
            id="Valencia.Request.catalogue"
            defaultMessage="Request Catalogue here"
          />
        </button>
      </div>
    )
  }

  return (
    <section id={nav} className="wrapper-samples-details--masiala">
      <h2 className="title-samples-details--masiala text-center text-lg-start">
        <FormattedMessage
          id="Saxony.ProductDetails.title"
          defaultMessage="Product Details"
        />
      </h2>
      <div className="row g-4">
        <div className="col-12 col-lg-5 d-xl-flex justify-content-xl-end">
          <div className="container-master-sample">
            <div className="position-relative">
              <div className="container-search-icon p-4">
                <div className="bg-light rounded-circle">
                  <FaSearch
                    className="p-2"
                    style={{ width: 36, height: 36 }}
                    color="gray"
                  />
                </div>
              </div>
              <GlassMagnifier
                className="large-img--masiala"
                imageSrc={`${imageSrc}`}
                imageAlt="sample-master-image"
                magnifierBorderColor="white"
                magnifierBackgroundColor="white"
                magnifierBorderSize={2}
                magnifierOffsetX={0}
                magnifierOffsetY={0}
                magnifierSize={150}
              />
              <p className="sample-detail-selected--masiala m-0">
                {state.sampleDetails !== undefined &&
                  `${state?.sampleDetails?.color} | ${state?.sampleDetails?.reference}`}

                {state.sampleDetails === undefined &&
                  `${firstSample.color} | ${firstSample.reference}`}
              </p>
            </div>
            <div className="d-block text-center">
              <button className="btn btn-rv mt-4 btn--desc-sx fs-6 col-md-5 col-lg-8 col-xl-6 col-xxl-5 py-2 sx-btn"
                onClick={() => handleAddSample(!state.sampleDetails ? firstSample : state.sampleDetails)}>
                Add A4 Sample
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 d-xl-flex justify-content-xl-end m-0 mt-lg-4">
          <SamplesList />
        </div>
        {isMobile && RenderBtnRequestCatalogue()}
      </div>
      <div className="my-2 my-lg-5">
        {!state?.sampleCount?.length ? (
          <></>
        ) : (
          <div className="position-relative">
            <SamplesCount
              toggle={() => handleClickInRequest(REQUEST_SAMPLES_INFO)}
            />
          </div>
        )}
        <ProductSpecs
          nav={nav}
          handleClickInRequest={handleClickInRequest}
          toggableAccordeon={toggableAccordeon}
          handleToggleAccordeon={handleToggleAccordeon}
        />
        {!isMobile && RenderBtnRequestCatalogue()}
      </div>
    </section>
  )
}

SamplesDetails.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object),
  handleClickInRequest: PropTypes.func,
  handleAddSample: PropTypes.func,
  handleOrderCatalogue: PropTypes.func,
  toggableAccordeon: PropTypes.bool,
  handleToggleAccordeon: PropTypes.func
}

export default SamplesDetails
